import React from "react";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { TransformConfiguratorPage } from "./TransformConfigurator";
import { ChatUI } from "./ChatUI";
import { createTheme, ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { TitleAppBar } from "./components";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4F00A9",
    },
  },
});

const options = [
  'Transform Configurator',
  'AIDD Playground',
  'LLM Chat',
  'YAML Editor',
  'Old Transform Configurator',
];

export const App = () => {
  const [page, setPage] = React.useState(0)

  const onChangePage = (page: number) => {
    setPage(page);
  }

  return (
    <CssBaseline>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <TitleAppBar options={options} onChangePage={onChangePage} />
          {page === 0 && (
            <TransformConfiguratorPage />
          )}
          {page === 1 && (
            <iframe 
            title="AIDD Playground" 
            style={{width:"100%", height:"1000px"}} src="/aidd.html">
          </iframe>
          )}
          {page === 2 && (
            <ChatUI />
          )}
          {page === 3 && (
            <iframe 
            title="YAML Editor" 
            style={{width:"100%", height:"1000px"}} src="/yaml/index.html">
          </iframe>
          )}
          {page === 4 && (
            <iframe 
            title="Old Tv2 Configurator" 
            style={{width:"100%", height:"1000px"}} src="/tv2.html">
          </iframe>
          )}
        </Provider>
      </ThemeProvider>
    </CssBaseline>
  );
};
