import * as React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Code, LastPage, OpenInNew } from "@mui/icons-material";
import { CodeBlock, a11yLight } from "react-code-blocks";
import { ConfigBuilderUtils } from "../../modules";
import { CONSOLE_LINK } from "../../constants";
import { RightPanelLayout } from "../../components";

export const ConfigPanel = ({ config }: { config: Record<string, any> }) => {
  const configStr = ConfigBuilderUtils.tv2ConfigToYAML(config);

  const handleCopyYaml = () => {
    if (configStr) {
      navigator.clipboard.writeText(configStr);
    }
  };

  const handleDownloadYaml = () => {
    if (configStr) {
      const element = document.createElement("a");
      const file = new Blob([configStr], { type: "text/yaml" });
      element.href = URL.createObjectURL(file);
      element.download = "gretel_config.yaml";
      document.body.appendChild(element); // Required for this to work in FireFox - GH copilot
      element.click();
    }
  };

  return (
    <RightPanelLayout
      buttonText="Config"
      buttonIcon={<Code />}
      panelTitle="Gretel Config.yml"
      panelTitleIcon={<LastPage />}
    >
      <CodeBlock
        text={configStr}
        language={"yaml"}
        showLineNumbers={true}
        theme={a11yLight}
      />
      <Box sx={{ p: 2 }} display="flex" gap={2}>
        <Button color="primary" variant="contained" onClick={handleCopyYaml}>
          Copy
        </Button>
        <Button
          color="secondary"
          variant="outlined"
          onClick={handleDownloadYaml}
        >
          Download
        </Button>
      </Box>
      <Box sx={{ p: 2 }}>
        <Typography sx={{ pb: 2 }}>
          You can copy and paste this configuration into your Gretel project.
        </Typography>
        <Button href={CONSOLE_LINK} target="_blank" endIcon={<OpenInNew />}>
          Go to Gretel Console
        </Button>
      </Box>
    </RightPanelLayout>
  );
};
