import { Stack, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { JoinRight } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../redux/storeHooks";
import {
  setViewMode,
  setShouldShowDiff,
  ViewMode,
} from "../../../redux/coreDuck";

export default function DisplayControls() {
  const viewMode = useAppSelector((state) => state.core.viewMode);
  const showDiff = useAppSelector((state) => state.core.shouldShowDiff);
  const dispatch = useAppDispatch();
  return (
    <Stack direction="row" spacing={1}>
      <ToggleButtonGroup
        size="small"
        value={viewMode}
        onChange={(e) =>
          dispatch(
            setViewMode((e.target as HTMLButtonElement).value as ViewMode)
          )
        }
        aria-label="table view controls"
      >
        <ToggleButton value="original" aria-label="view original data">
          Original
        </ToggleButton>
        <ToggleButton value="transformed" aria-label="view transformed data">
          Transformed
        </ToggleButton>
        <ToggleButton value="both" aria-label="view both data versions">
          Both
        </ToggleButton>
      </ToggleButtonGroup>
      <Tooltip title="Toggle diff highlighting">
        <ToggleButton
          aria-label="toggle diff highlighting"
          size="small"
          value="diff"
          color={showDiff ? "primary" : "standard"}
          selected={showDiff}
          onChange={() => dispatch(setShouldShowDiff(!showDiff))}
        >
          <JoinRight />
        </ToggleButton>
      </Tooltip>
    </Stack>
  );
}
