import React from "react";
import {
  Button,
  IconButton,
  Drawer,
  Box,
  AppBar,
  Toolbar,
  Typography,
} from "@mui/material";

type RightPanelLayoutProps = React.PropsWithChildren<{
  buttonIcon?: React.ReactNode;
  buttonText: string;
  panelTitle: string;
  panelTitleIcon: React.ReactNode;
}>;

export const RightPanelLayout = ({
  buttonText,
  buttonIcon,
  panelTitle,
  panelTitleIcon,
  children,
}: RightPanelLayoutProps) => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <>
      <Button
        variant="contained"
        size="small"
        color="primary"
        startIcon={buttonIcon}
        onClick={toggleDrawer(true)}
      >
        {buttonText}
      </Button>
      <Drawer anchor={"right"} open={open} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 500 }} role="presentation">
          <AppBar position="static">
            <Toolbar variant="dense">
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={toggleDrawer(false)}
              >
                {panelTitleIcon}
              </IconButton>
              <Typography variant="h6" color="inherit" component="div">
                {panelTitle}
              </Typography>
            </Toolbar>
          </AppBar>
          {children}
        </Box>
      </Drawer>
    </>
  );
};
