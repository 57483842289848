import React from "react";
import {
  Button,
  FormControl,
  Box,
  Stack,
  Tooltip,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import { AutoAwesome } from "@mui/icons-material";
import { useAppSelector } from "../../../redux/storeHooks";
import { useInferenceMutation } from "../../../api";
import { LinearProgressBar } from "../../../components/LinearProgressBar";
/**
 * A standalone form to handle expression writing.
 * This form will hook into our LLM to use plain
 * language to write expressions without modifying
 * the state of the page until confirmed.
 */
export const ExpressionForm = ({
  field,
  entity,
  allColumnNames,
  expression,
  onChange,
}: {
  field: string;
  entity?: string | null;
  allColumnNames: string[];
  expression: string;
  onChange: (newVal?: string) => void;
}) => {
  const apiKey = useAppSelector((state) => state.core.apiKey);
  const inputRef = React.useRef<{ value: string }>(null);

  const [infer, { isLoading: inferenceIsLoading }] = useInferenceMutation();

  const handleConfirm = () => {
    const inputValue = inputRef.current?.value;
    onChange(inputValue);
  };

  const generateLLMExpression = () => {
    const input = inputRef.current?.value;
    if (!apiKey || !input) {
      return;
    }

    const prompt = createPromptForAi(input || "", allColumnNames, {
      name: field,
      entity: entity,
    });

    infer({ apiKey, prompt })
      .unwrap()
      .then((res) => {
        const result = res.text;
        if (result) {
          inputRef.current!.value = result;
        }
      })
      .catch((err) => {
        console.error("Error generating expression via inference:", err);
      });
  };

  return (
    <Box
      sx={{ p: 2, display: "flex", flexDirection: "column", minWidth: "400px" }}
    >
      <Stack spacing={2}>
        <FormControl size="small" autoFocus>
          <OutlinedInput
            fullWidth
            autoFocus
            type="text"
            placeholder="Example: Generate a random number"
            defaultValue={expression}
            inputProps={{
              ref: inputRef,
            }}
            size="small"
            endAdornment={
              <Tooltip title="Generate expression with AI">
                <IconButton
                  color="primary"
                  size="small"
                  onClick={generateLLMExpression}
                >
                  <AutoAwesome />
                </IconButton>
              </Tooltip>
            }
          />
        </FormControl>
        {inferenceIsLoading && <LinearProgressBar />}
        <p>
          Write an expression in python, or use AI to generate a python string
          using plain language.
        </p>
        <Button variant="contained" onClick={handleConfirm}>
          Apply
        </Button>
      </Stack>
    </Box>
  );
};

const createPromptForAi = (
  prompt: string,
  columnNames: string[],
  col: { name: string; entity?: string | null }
) => {
  let colVariables = columnNames.map((name) => `row["${name}"]`).join("\n");

  return (
    "Given the following variables:\n" +
    colVariables +
    `
  fake: an instance of a Python Faker class, allowing for example "fake.name()"
  random: an instance of Python's random class, allowing for example "random.randomInt()"
  index: the current row index

  Return a jinja-compliant Python expression for a new value of the '${col.name}' of type '${col.entity}' column using + for concatenation that satisfies the following request.
  Do not use string templating with {{}}. Do not put quotes around the result.
  ` +
    prompt
  );
};
