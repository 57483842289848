import React from "react";
import { FormControl, Select, MenuItem, FormLabel } from "@mui/material";

export const SelectLocale = ({
  locale,
  onChangeLocale,
}: {
  locale: string;
  onChangeLocale: (newLocale: string) => void;
}) => {
  return (
    <FormControl
      size="small"
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 1,
      }}
    >
      <FormLabel htmlFor="locale-select">Locale</FormLabel>
      <Select
        value={locale}
        onChange={(e) => onChangeLocale(e.target.value)}
        id="locale-select"
      >
        {locales.map((locale) => (
          <MenuItem key={locale} value={locale}>
            {locale}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const locales = [
  "ar_AA",
  "ar_AE",
  "ar_BH",
  "ar_EG",
  "ar_JO",
  "ar_PS",
  "ar_SA",
  "az_AZ",
  "bg_BG",
  "bn_BD",
  "bs_BA",
  "cs_CZ",
  "da_DK",
  "de",
  "de_AT",
  "de_CH",
  "de_DE",
  "dk_DK",
  "el_CY",
  "el_GR",
  "en",
  "en_AU",
  "en_BD",
  "en_CA",
  "en_GB",
  "en_IE",
  "en_IN",
  "en_MS",
  "en_NZ",
  "en_PH",
  "en_PK",
  "en_TH",
  "en_US",
  "es",
  "es_AR",
  "es_CA",
  "es_CL",
  "es_CO",
  "es_ES",
  "es_MX",
  "et_EE",
  "fa_IR",
  "fi_FI",
  "fil_PH",
  "fr_BE",
  "fr_CA",
  "fr_CH",
  "fr_FR",
  "fr_QC",
  "ga_IE",
  "he_IL",
  "hi_IN",
  "hr_HR",
  "hu_HU",
  "hy_AM",
  "id_ID",
  "it_CH",
  "it_IT",
  "ja_JP",
  "ka_GE",
  "ko_KR",
  "la",
  "lb_LU",
  "lt_LT",
  "lv_LV",
  "mt_MT",
  "ne_NP",
  "nl_BE",
  "nl_NL",
  "no_NO",
  "or_IN",
  "pl_PL",
  "pt_BR",
  "pt_PT",
  "ro_RO",
  "ru_RU",
  "sk_SK",
  "sl_SI",
  "sq_AL",
  "sv_SE",
  "sw",
  "ta_IN",
  "th",
  "th_TH",
  "tl_PH",
  "tr_TR",
  "tw_GH",
  "uk_UA",
  "vi_VN",
  "yo_NG",
  "zh_CN",
  "zh_TW",
  "zu_ZA",
];
