import React from "react";
import {
  Box,
  Toolbar,
  Button,
  AppBar,
  Dialog,
  DialogTitle,
  TextField,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Menu,
} from "@mui/material";
import { CheckCircleOutlined, KeyOutlined } from "@mui/icons-material";
import { useAppDispatch } from "../redux/storeHooks";
import { setApiKey } from "../redux/coreDuck";
import Logo from "../logo.svg";
import { CONSOLE_API_KEY_URL } from "../constants";

export const TitleAppBar = (
  { 
    onChangePage, 
    options
  } : { 
    onChangePage: (page: number) => void;
    options: string[];
  }) => {
  const apiKey = localStorage.getItem('GRETEL_API_KEY') || '';
  const dispatch = useAppDispatch();

  const [showAuth, setShowAuth] = React.useState<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    onChangePage(index)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Show the auth dialog automatically if the user hasn't authenticated
  React.useEffect(() => {
    if (!apiKey) {
      setShowAuth(true);
    }
  }, []);

  return (
    <>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Box
            sx={{
              pr: 2,
              mr: 2,
              borderRight: "1px solid",
              borderRightColor: "rgba(255, 255, 255, 0.3)",
              height: 24,
              position: "relative",
              top: 2,
            }}
          >
            <img style={{height:24}} src={Logo} alt="Gretel Logo" height={24} />
          </Box>
          <List
            component="nav"
            aria-label="Device settings"
            sx={{ flexGrow: 1 }}
          >
            <ListItemButton
              id="lock-button"
              onClick={handleClickListItem}
            >
              <ListItemText
                primary={options[selectedIndex] + ' ▼'}
                secondary=""
              />
            </ListItemButton>
          </List>
          <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'lock-button',
              role: 'listbox',
            }}
          >
            {options.map((option, index) => (
              <MenuItem
                key={option}
                selected={index === selectedIndex}
                onClick={(event) => handleMenuItemClick(event, index)}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
          <Button
            color="inherit"
            onClick={() => setShowAuth(true)}
            startIcon={apiKey ? <CheckCircleOutlined /> : <KeyOutlined />}
          >
            {apiKey ? "Authenticated" : "Authenticate"}
          </Button>
        </Toolbar>
      </AppBar>
      <Dialog
        open={showAuth}
        onClose={() => setShowAuth(false)}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const apiKey = formJson.apiKey;
            dispatch(setApiKey(apiKey || ""));
            localStorage.setItem('GRETEL_API_KEY', apiKey || '');
            setShowAuth(false);
          },
        }}
      >
        <DialogTitle>Provide Your Gretel API Key To Authenticate</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Your API key is a secret token that allows you to authenticate with
            the Gretel API. You can find your key in the{" "}
            <a href={CONSOLE_API_KEY_URL} rel="noreferrer" target="_blank">
              Gretel Console.
            </a>
          </DialogContentText>
          <TextField
            id="apiKey"
            name="apiKey"
            type="password"
            defaultValue={apiKey}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
